import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import { Avatar, IAvatarProps } from "../Avatar"
import { useTheme } from "@material-ui/core/styles"
import { parseTimestampForLocaleDateString } from "../../../utils/parsers"
import { Colors } from "../../../styles/Colors"
import { IOrderPromoPhoto } from "../Promo/AddMedia"
import { IImageViewerProps, ImageViewer } from "../ImageViewer"
import { ReactElement, useMemo, useState } from "react"
import { IPhoto } from "../../../utils/photos"
import { Download } from "../Download"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"

export interface IMessageProps {
  from: "User" | "Partner"
  timestamp: number
  date: Date
  message: string
  avatar?: IAvatarProps
  isIgnored: boolean
  type: "message"
  media?: IOrderPromoPhoto[]
  id: string
}

export const Message = (props: IMessageProps): JSX.Element => {
  const theme = useTheme()
  const [imageViewerOpen, setImageViewerOpen] = useState<boolean>(false)
  const [imageViewerPosition, setImageViewerPosition] = useState<number>(0)

  const from = useMemo(() => props.from, [props.from])
  const timestamp = useMemo(() => props.timestamp, [props.timestamp])
  const date = useMemo(() => props.date, [props.date])
  const message = useMemo(() => props.message, [props.message])
  const avatar = useMemo(() => props.avatar, [props.avatar])
  const media = useMemo(() => props.media, [props.media])
  const [imageViewerProps, setImageViewerProps] = useState<IImageViewerProps>({
    images: media ? (media as IPhoto[]) : [],
    open: imageViewerOpen,
    onClose: () => {
      setImageViewerOpen(false)
    },
    position: imageViewerPosition,
    setPosition: setImageViewerPosition,
  })

  const formatMessage = (message: string): ReactElement => {
    let formattedMessage: ReactElement = <span>{message}</span>

    if (message.includes("http") || message.includes("https")) {
      formattedMessage = (
        <a
          href={message}
          target={"_blank"}
          style={{ color: theme.palette.primary.main, wordWrap: "break-word" }}
          rel="noreferrer"
        >
          {message}
        </a>
      )
    }

    return (
      <Typography className="message" variant={"body2"}>
        {formattedMessage}
      </Typography>
    )
  }

  if (from === "Partner" && message === "Duplicate message not sent.") {
    return <></>
  }

  return (
    <StyledMessage from={from}>
      {parseTimestampForLocaleDateString(timestamp, date) && (
        <div className="timeStamp">
          <Typography
            color={theme.palette.text.secondary}
            variant={"caption"}
            align={"center"}
          >
            {parseTimestampForLocaleDateString(timestamp, date)}
          </Typography>
        </div>
      )}

      {media && media?.length > 0 && (
        <>
          <MediaMessageContainer
            isUserMessage={from === "User"}
            isOnlyImage={media.length === 1}
          >
            {media.map((image, index) => {
              return (
                <div className={"image-wrapper"} key={index}>
                  <div
                    className={"image-container"}
                    key={index}
                    onClick={() => {
                      if (imageViewerProps) {
                        setImageViewerProps(imageViewerProps)
                        setImageViewerPosition(index)
                        setImageViewerOpen(true)
                      }
                    }}
                  >
                    <img
                      src={
                        image.isVideo ? image.thumbnailUrl : image.originalUrl
                      }
                      width={media?.length === 1 ? "275px" : "150px"}
                      height={media?.length === 1 ? "275px" : "150px"}
                      style={{
                        width: media?.length === 1 ? "275px" : "150px",
                        height: media?.length === 1 ? "275px" : "150px",
                        borderRadius: 6,
                        objectFit: "scale-down",
                      }}
                    />

                    {image.isVideo && (
                      <PlayArrowIcon
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "#fff",
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: "50%",
                          padding: "4px",
                          fontSize: "40px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              )
            })}
            {from === "Partner" && (
              <div className={"image-downloader"}>
                <Download files={media as unknown as IPhoto[]} />
              </div>
            )}
          </MediaMessageContainer>
          <ImageViewer
            {...imageViewerProps}
            position={imageViewerPosition}
            open={imageViewerOpen}
            showDownloadBtn
          />
        </>
      )}

      {message && (
        <div className="entry">
          {from === "Partner" && avatar && (
            <div className={"avatar"}>
              <Avatar
                online={avatar.online}
                profileImage={avatar.profileImage}
                size={"Tiny"}
              />
            </div>
          )}
          {formatMessage(message)}
        </div>
      )}
    </StyledMessage>
  )
}

const MediaMessageContainer = styled.div<{
  isUserMessage: boolean
  isOnlyImage: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.isUserMessage ? "end" : "start")};
  gap: 5px;

  .image-container {
    border-radius: 6px;
    width: ${(props) => (props.isOnlyImage ? "275px" : "150px")};
    min-width: ${(props) => (props.isOnlyImage ? "275px" : "150px")};
    height: ${(props) => (props.isOnlyImage ? "275px" : "150px")};
    position: relative;
    cursor: pointer;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  }

  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-downloader {
    display: flex;
    align-items: end;
  }
`

export const StyledMessage = styled.div<{ from: string }>`
  display: flex;
  flex-direction: column;
  padding: 4px 10px;
  gap: 4px;

  .entry {
    display: flex;
    gap: 5px;
    justify-content: ${(props) =>
      props.from === "User" ? "flex-end" : "flex-start"};

    .avatar {
      height: 16px;
      display: flex;
      flex-direction: column;
      align-self: flex-end;
    }

    .message {
      padding: 16px;
      background: ${(props) =>
        props.from === "User"
          ? Colors.blues.chatMessage
          : Colors.grays.chatMessage};
      border-radius: 6px;
      width: fit-content;
      max-width: 80%;
      white-space: pre-wrap;
      word-break: normal;
      overflow-wrap: break-word;
    }
  }

  .timeStamp {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }
`
