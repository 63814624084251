import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  Box,
  Divider,
  Drawer,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { UserContext } from "../../../../contexts/user"
import { MessageActionTypes, MessageContext } from "../../../../contexts/websocket"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { messagesWidth } from "../../../../utils/constants"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Messages } from "../../../Messaging/Messages"
import { Chat } from "../../Chat/Chat"
import styled from "@emotion/styled"
import { getWindowDimensions } from "../../../../utils/windowDimensions"
import { useRouter } from "next/router"
import { ChatActionTypes, ChatContext } from "../../../../contexts/chat"
import { post } from "../../../../utils/requests"
import { ArgJSONMap } from "../../../../utils/argjsonmap"

const messagesButtonHeight = 48

export const MessagesDrawer = (): JSX.Element => {
  const theme = useTheme()
  const router = useRouter()
  const { context } = useContext(UserContext)
  const { messageContext, messageDispatch } = useContext(MessageContext)
  const { context: chatContext, dispatch: chatDispatch } =
    useContext(ChatContext)
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const isAuthenticated = useMemo(
    () => context.isAuthenticated,
    [context.isAuthenticated]
  )
  const messageDrawerOpen = useMemo(
    () => chatContext.messageDrawerOpen,
    [chatContext.messageDrawerOpen]
  )
  const otherUser = useMemo(
    () => chatContext.otherUser,
    [chatContext.otherUser]
  )
  const [unreadMessageCount, setUnreadMessageCount] = useState(messageContext.unreadMessageCount)

  useEffect(() => {
    if (!messageContext.otherProfileUUID || !chatContext.messageDrawerOpen || messageContext.unreadMessageCount === 0) {
      setUnreadMessageCount(messageContext.unreadMessageCount)
      return
    }
    const postData = {
      other_profile_id: messageContext.otherProfileUUID,
      profile_id: context.uuid,
    }
    post("/update_messages_as_read/", postData)
      .then((response) => {
        const data = ArgJSONMap.fromParsedJson(response.data)
        setUnreadMessageCount(data.getNumber("total_unread"))
        messageDispatch({
          type: MessageActionTypes.UpdateUnreadMessageCount,
          payload: {
            unreadMessageCount:
              data.getNumber("total_unread"),
          },
        })
      })
      .catch((errors) => console.error(errors))
  }, [messageContext.unreadMessageCount, chatContext.messageDrawerOpen])

  useEffect(() => {
    if (!otherUser || !messageDrawerOpen) {
      return
    }

    const { width } = getWindowDimensions()
    if (width < theme.breakpoints.values.sm) {
      const newPath = `/messages/${otherUser}`
      if (newPath !== router.asPath) {
        router.push(newPath)
      }
    }
  }, [otherUser, messageDrawerOpen])

  if (!isAuthenticated) {
    return <></>
  }

  if (!isDesktop) {
    return <></>
  }

  return (
    <Box
      className={"floating-action-container"}
      sx={{ display: { xs: "none", md: "flex" } }}
    >
      {!messageDrawerOpen && (
        <StyledMessageButton
          onClick={() =>
            chatDispatch({
              type: ChatActionTypes.SetMessageDrawerOpen,
              payload: !messageDrawerOpen,
            })
          }
          backgroundColor={
            unreadMessageCount > 0
              ? theme.palette.primary.main
              : theme.palette.background.paper
          }
          color={
            unreadMessageCount > 0
              ? theme.palette.background.paper
              : theme.palette.text.primary
          }
          theme={theme}
          flash={unreadMessageCount > 0}
        >
          <Typography
            color={
              unreadMessageCount > 0
                ? theme.palette.background.paper
                : theme.palette.text.primary
            }
            variant={"subtitle1"}
          >
            Messages {unreadMessageCount !== 0 && `(${unreadMessageCount})`}
          </Typography>
          <ExpandLessIcon />
        </StyledMessageButton>
      )}
      <Drawer
        className={"messages-modal"}
        sx={{ width: messagesWidth, left: "auto" }}
        PaperProps={{
          sx: {
            width: messagesWidth,
            left: "auto",
            height: "100vh",
            boxShadow:
              "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
          },
        }}
        variant="persistent"
        anchor="bottom"
        open={messageDrawerOpen}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <DrawerHeader
          onClick={() =>
            chatDispatch({
              type: ChatActionTypes.SetMessageDrawerOpen,
              payload: !messageDrawerOpen,
            })
          }
        >
          <Typography variant="subtitle1">
            Messages ({unreadMessageCount})
          </Typography>
          <ExpandMoreIcon />
        </DrawerHeader>
        <Divider />
        {!otherUser && <Messages headerOffset={messagesButtonHeight} />}
        {otherUser && <Chat username={otherUser} />}
      </Drawer>
    </Box>
  )
}

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  height: ${messagesButtonHeight}px;
`

const StyledMessageButton = styled.div<{
  backgroundColor: string
  color: string
  theme: Theme
  flash: boolean
}>`
  max-width: ${messagesWidth}px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 8px;
  padding-left: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  animation: ${(props) =>
    props.flash ? "newMessagePulse 4s linear infinite" : "none"};

  position: absolute;
  bottom: 0;
  right: 0;

  @keyframes newMessagePulse {
    0% {
      background: ${(props) => props.theme.palette.primary.main};
    }

    50% {
      background: ${(props) => props.theme.palette.primary.light};
    }

    100% {
      background: ${(props) => props.theme.palette.primary.main};
    }
  }
`
